/* App.css */

/* Ensure the body takes the full height */
html, body, #root {
  /* height: 100%; */
  margin: 0;
  font-family: 'Arial', sans-serif;
}

/* Header Styling */
#navv {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  width: 100%;
    position:fixed;
  top:0px;
  z-index: 100;
}

 

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-nav .nav-link {
  margin-top: 0px;
  padding-top: 0px;
 }

/* SearchBar Styling */
.form-control {
  
  border-radius: 0;
  border: 2px solid #007bff;
}

button {
  border-radius: 0;
}

/* Category List Styling */
.nav-pills .nav-link {
  /* padding: 10px 20px; */
  margin: 5px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #007bff;
  transition: background-color 0.2s;
}
/* App.css */

/* Styling for the ad count */
.ad-count {
  font-size: 0.85rem;
  color: #6c757d; /* Gray muted color */
  display: block;
  margin-top: 2px; /* Minimal gap between category name and ad count */
}

/* Styling for category items */
.nav-item {
  margin-bottom: 5px; /* Minimal gap between each category */
}

/* Styling for the entire category link */
.category-link {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-radius: 4px;
  color: #495057; /* Bootstrap dark gray color */
  text-decoration: none;
}

/* Hover effect on the entire category */
.category-link:hover {
  background-color: #007bff; /* Bootstrap primary color */
  color: #fff; /* Change text color to white */
}

/* Hover effect for the ad count */
.category-link:hover .ad-count {
  color: #fff; /* Make the ad count white as well */
}

.nav-pills .nav-link:hover {
  background-color: #007bff;
  color: white;
}

/* ProductCard Styling */
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

.card-body {
  text-align: center;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.card-text {
  color: #6c757d;
}

/* Container for better alignment */
.container {
  max-width: 1200px;
}

.row {
  margin: 20px 0;
}


/* App.css */

/* Global settings */
html, body, #root {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.container {
  max-width: 1200px;
}

/* SideNav styles */
.sidenav {
  position: sticky;
  top: 80px;
  max-height: calc(100vh - 150px); /* Full height minus header */
  overflow-y: auto;
  border-right: 1px solid #dee2e6;
  background-color: #f8f9fa;
}

.sidenav h5 {
  font-weight: bold;
  color: #343a40;
}

.sidenav .nav-link {
  font-size: 1rem;
  padding: 8px;
  color: #495057;
}

.sidenav .nav-link:hover {
  background-color: #007bff;
  color: #fff;
}


/* ------  */
/* App.css */

/* Make category list 2 columns on mobile */
@media (max-width: 768px) {
  .sidenav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .sidenav .nav-item {
    flex: 0 0 48%; /* Take up about half of the container width */
    margin-bottom: 10px; /* Add spacing between items */
  }
}


/* App.css */

/* Styling for the ad count */
.ad-count {
  font-size: 0.85rem;
  color: #6c757d;
  display: block;
  margin-top: 2px;
}

/* Styling for category items */
.nav-item {
  margin-bottom: 0px;
  margin-top: 0px;
}

/* Styling for the entire category link */
.category-link {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  border-radius: 4px;
  color: #495057;
  text-decoration: none;
}

.category-link:hover {
  background-color: #007bff;
  color: #fff;
}

.category-link:hover .ad-count {
  color: #fff;
}

/* Make category list 2 columns on mobile */
@media (max-width: 768px) {
  .sidenav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .sidenav .nav-item {
    flex: 0 0 48%;
    margin-bottom: 10px;
  }
}

/* ---  */
/* App.css */

/* Mobile navigation bar */
.mobile-nav {
  display: none; /* Initially hidden, shown only on mobile via media query */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1000;
}

/* Mobile nav item styles */
.mobile-nav .nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #495057;
  text-decoration: none;
  font-size: 0.85rem;
}

/* Icon styles */
.mobile-nav .icon {
  font-size: 1.5rem;
  margin-bottom: 2px; /* Small margin between icon and text */
}

/* Active route styling */
/* Active state for the NavLink */
.mobile-nav .nav-item.active {
  color: #db571a; /* Active color for both icon and text */
}

.mobile-nav .nav-item.active .icon {
  color: #db571a; /* Change icon color when active */
}

/* Optional: Hover effect */
.mobile-nav .nav-item:hover {
  color: #0056b3; /* Slightly darker on hover */
}


/* Media query to show mobile nav only on screens smaller than 768px */
@media (max-width: 768px) {
  .mobile-nav {
    display: flex; /* Show on mobile */
  }
  #footer1{
    display: none;
  }
}
@media (min-width: 768px) {
  #footer1{
    display: block;
  }

  .mobile-nav {
    display: none; /* Show on mobile */
  }
}

/* ---  */

/* Ensure the image remains square */
.image-container {
  width: 100%; /* Full width of the card */
  padding-top: 100%; /* Makes the container's height equal to its width, creating a square */
  position: relative; /* Allows positioning the image inside the square */
  overflow: hidden; /* Ensures the image won't overflow the container */
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire square without distortion */
}

a {
  text-decoration: none !important;
}

/* General skeleton style */
.skeleton-card {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
}

.skeleton-image {
  width: 100%;
  padding-top: 100%; /* Aspect ratio for a square */
  background-color: #e0e0e0;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.skeleton-info {
  margin-top: 10px;
}

.skeleton-title, 
.skeleton-price, 
.skeleton-description {
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

/* Individual widths for the text placeholders */
.skeleton-title {
  width: 70%;
  margin-bottom: 10px;
}

.skeleton-price {
  width: 40%;
  margin-bottom: 10px;
}

.skeleton-description {
  width: 90%;
  margin-bottom: 5px;
}

/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-position: -150% 0;
  }
  100% {
    background-position: 150% 0;
  }
}

.skeleton-image,
.skeleton-title,
.skeleton-price,
.skeleton-description {
  background: linear-gradient(
    90deg,
    #e0e0e0 25%,
    #f8f8f8 50%,
    #e0e0e0 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}


/* Product card styling */
.product-card {
  margin-bottom: 20px;
}

/* Text description: one line with ellipsis */
.card-description {
  white-space: nowrap; /* Prevents wrapping to the next line */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds '...' at the end of overflowing text */
  font-size: 0.9rem;
  color: #6c757d; /* Subtle text color */
}

/* Additional styles */
.card-text.price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .product-card {
    width: 100%; /* Two columns on mobile view */
  }
}


/* App.css */

/* Grid container for two-column layout */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 16px; /* Gap between grid items */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr; /* Single column on small screens */
  }
}

/* Styling for grid items */
.grid-item {
  background-color: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
}

/* Specific styles for side navigation */
.sidenav .grid-item {
  text-align: left;
}

/* Specific styles for product list */
.product-list .grid-item {
  text-align: center;
}


/* Footer styles */
footer {
  background-color: #343a40;
  color: #ffffff;
}

footer h5 {
  font-weight: bold;
  font-size: 1.2rem;
}

footer a {
  color: #ffffff;
}

footer a:hover {
  color: #007bff;
  text-decoration: underline;
}

footer hr {
  border-top: 1px solid #fff;
}

footer .text-center {
  padding: 10px 0;
}



/* General styling */
body {
  font-family: Arial, sans-serif;
}

/* form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 10px;
} */

input, textarea {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* PostComponent */
.post-container {
  margin-top: 80px;

  padding: 20px;
}

/* AccountComponent */
.account-container {
  padding: 20px;
  margin-top: 80px;

}

.logout-btn {
  background-color: red;
}

.account-info p {
  margin: 5px 0;
}

/* MessageComponent */
.message-container {
  padding: 20px;
  margin-top: 80px;
}

.message-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.message-item {
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.message-item.support {
  background-color: #f1f1f1;
  text-align: left;
}

.message-item.user {
  background-color: #007bff;
  color: white;
  text-align: right;
}

input[type="text"] {
  width: calc(100% - 80px);
}

/* button[type="submit"] {
  width: 60px;
  margin-left: 10px;
} */


/* MobileIconNav styling */

 /* Grid container for responsive layout */
.icon-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); /* Adjusts the column size dynamically */
  gap: 10px; /* Space between grid items */
}

/* Styling the card container */
.icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for professional look */
}

/* Icon container for alignment */
.icon-container {
  text-align: center;
}

/* Styling for the icon itself */
.icon {
  font-size: 30px; /* Adjust icon size */
  color: #007bff; /* Icon color */
  margin-bottom: 5px;
}

/* Category label */
.icon-label {
  font-size: 12px;
  width: 100%; /* Ensure it takes the width of the container */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Show "..." for overflowed text */
  color: #333;
}


/* -----  */

/* Base styles for the icon */
.icon {
  font-size: 30px;
  color: #00ff2a; /* Default icon color */
  margin-bottom: 5px;
}

/* Active state for the NavLink */
.icon-item.active .icon {
  color: #ff5733; /* Change icon color when active */
}

/* Optional: you can also change the label color */
.icon-item.active .icon-label {
  color: #ff5733; /* Change label color when active */
}


/* Ensure this class only shows on mobile */
.d-block.d-md-none {
  display: block;
}

.d-md-none {
  display: none;
}
/* Custom styles for mobile and desktop headers */

/* Center logo on mobile */
.navbar-brand.mx-auto {
  text-align: center;
}

/* Ensure navbar is properly styled on mobile and desktop */
.navbar-nav {
  margin-left: auto;
  /* margin-right: auto; */
}


.favourite-card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.favourite-card img {
  border-radius: 8px;
}

.favourite-card .card-body {
  padding: 1rem;
}

.favourite-card .card-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.favourite-card .card-text {
  font-size: 0.9rem;
}
